<template>
  <div>
    <Pane />

    <a-card class="container" :loading="loading">
      <div class="card">
        <img class="cover" v-if="detail.cover" :src="detail.cover.completePath" alt />
        <div class="content">
          <div class="header">
            <div class="name">{{detail.name}}</div>

            <a-tag color="green" v-for="item in labelList" :key="item">{{ item }}</a-tag>
          </div>

          <div class="position">课程位置：{{detail.indexName}}</div>
          <div class="meta">
            <span style="padding-right: 12px">上传者：{{ detail.creator }}</span>
            上传时间：{{ detail.createAt}}
          </div>
        </div>
        <div class="extra">
          <div class="duration">总学时：{{ detail.duration}}分钟</div>
          <div style="margin-top: 12px;margin-bottom: 6px">我的进度：</div>
          <div>
            <a-progress :percent="detail.courseProgress || 0" />
          </div>
        </div>
      </div>

      <div class="title">课程介绍</div>
      <div class="remark">{{detail.remark}}</div>

      <div v-if="Array.isArray( detail.chapters)">
        <div class="title">课程目录</div>

        <div class="chapters">
          <div
            class="item"
            v-for="item in detail.chapters"
            :key="item.id"
            :class="item.chapterProgress >= 100 ? 'active' : ''"
          >
            <div class="dot"></div>
            <div class="name">{{item.name}}</div>
            <a-tag color="green" v-if="item.type === 'course'">课程</a-tag>
            <a-tag color="blue" v-if="item.type === 'exam'">考试</a-tag>

            <div style="margin-left: auto;width: 160px">
              <a-progress :percent="item.chapterProgress || 0" size="small" />
            </div>

            <div style="margin:0 8px">{{item.duration}}分钟</div>

            <a-button
              type="primary"
              size="small"
              :disabled="item.chapterProgress >= 100 && item.type !== 'course'"
              @click="continueStudy(item)"
            >{{ item.type === 'course' ? "学习" : '考试' }}</a-button>
          </div>
        </div>
      </div>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button type="primary" :disabled="disabled" @click="() => continueStudy()">继续学习</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";

function fetchDetail(data) {
  return request({
    url: "/office-service/study/user/course/" + data.courseId,
    method: "post"
  });
}

export default {
  data() {
    return {
      courseId: "",
      loading: false,
      detail: {}
    };
  },

  computed: {
    labelList() {
      return typeof this.detail.label === "string"
        ? this.detail.label
            .split(/,|，/)
            .filter((item, index, self) => self.indexOf(item) === index)
        : [];
    },

    disabled() {
      if (Array.isArray(this.detail.chapters)) {
        let flag = true;
        for (let i = 0; i < this.detail.chapters.length; i++) {
          const item = this.detail.chapters[i];
          if (item.chapterProgress < 100) {
            flag = false;
            break;
          }
        }
        return flag;
      } else {
        return true;
      }
    }
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      const { courseId } = this.$route.query;
      this.courseId = courseId;
      this.loading = true;
      fetchDetail({
        courseId
      })
        .then(res => {
          this.detail = res || {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    continueStudy(item) {
      if (item && item.id) {
        if (item.type === "course") {
          this.$router.push(
            `/knowledge/study/play?courseId=${this.courseId}&chapterId=${item.id}`
          );
          return;
        }
        if (item.type === "exam") {
          this.$router.push(
            `/knowledge/exam/index/start?courseId=${this.courseId}&chapterId=${item.id}&id=${item.examId}`
          );
          return;
        }
      }

      if (Array.isArray(this.detail.chapters)) {
        let chapter;
        for (let i = 0; i < this.detail.chapters.length; i++) {
          const item = this.detail.chapters[i];
          if (item.chapterProgress < 100) {
            chapter = item;
            break;
          }
        }
        if (chapter) {
          if (chapter.type === "course") {
            this.$router.push(
              `/knowledge/study/play?courseId=${this.courseId}&chapterId=${chapter.id}`
            );
            return;
          }
          if (chapter.type === "exam") {
            this.$router.push(
              `/knowledge/exam/index/start?courseId=${this.courseId}&chapterId=${chapter.id}&id=${chapter.examId}`
            );
            return;
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 8px;
  .card {
    padding: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    display: flex;

    .cover {
      display: block;
      width: 240px;
      margin-right: 16px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .name {
        font-weight: bold;
        font-size: 20px;
        margin-right: 16px;
      }
    }
    .position {
      margin-bottom: 8px;
    }
    .meta {
      margin-top: auto;
      color: #999;
    }

    .extra {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
      width: 160px;
    }
  }

  .title {
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  .remark {
    line-height: 1.8em;
  }

  .chapters {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .dot {
        background-color: #c9c3c3;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 8px;

        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 16px;
          left: 3px;
          width: 1.5px;
          height: 24px;
          background-color: #c9c3c3;
        }
      }

      .name {
        margin-right: 8px;
        font-weight: 500;
      }
    }

    .item:last-child {
      .dot {
        &::after {
          width: 0;
        }
      }
    }

    .active {
      .dot {
        background-color: #1890ff;
        &::after {
          background-color: #1890ff;
        }
      }
    }
  }
}

.center {
  padding: 80px 0;
}
</style>